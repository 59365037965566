import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

//import IdleVue from "idle-vue";

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'
import useJwt from '@/auth/jwt/useJwt'
import { initialAbility } from '@/libs/acl/config'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Axios Mock Adapter
// import '@/@fake-db/db'

// FONTAWESOME
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faEdit, faUserSecret, faLink, faUnlock, faUnlink, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { faTrashCan } from '@fortawesome/free-regular-svg-icons'
library.add(faEdit, faUserSecret, faLink, faUnlock, faUnlink, faTrash, faTrashAlt, faTrashCan)
Vue.component('font-awesome-icon', FontAwesomeIcon)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

export function externalLogout() {
  const event = new CustomEvent('userLoggedOut');
  window.dispatchEvent(event);
  location.reload();
}
export function isEducamadridFunction() {
  if (process.env.VUE_APP_DOMAIN_BACKEND.includes('educa.madrid.org')) return true
  if (process.env.VUE_APP_DOMAIN_LEARNING_PATHS.includes('educa.madrid.org')) return true
  if (process.env.VUE_APP_DOMAIN_REPORTS.includes('educa.madrid.org')) return true
  if (process.env.VUE_APP_ENVIROMENT.includes('educamadrid')) return true
  return false
}

export function getUserRole() {
  const userData = store.getters['user/getUserData'];
  if (userData) {
    return userData.role;
  }
  return null;
}

export function manteinance() {
  const event = new CustomEvent('userLoggedOut');
  window.dispatchEvent(event);
  router.push({ name: 'error-503' });
}
export function logoImageFunction () {
  switch (process.env.VUE_APP_ENVIROMENT) {
    case 'production':
      return require('@/assets/images/logo/logo_login.png');
    case 'preproduction':
      return require('@/assets/images/logo/logoSmilePRE.png');
    case 'development':
      return require('@/assets/images/logo/logoSmileDev.png');
    default:
      return require('@/assets/images/logo/logo_login.png');
  }
}
/* 
GLOBAL DATA / METHODS
A Mixin is an object that contains the same options 
as a component and can be reused and distributed 
between Vue components.
*/
Vue.mixin({
  data() {
    return {}
  },
  methods: {
    isEducamadrid(){
      return isEducamadridFunction()
    },
    resolveUserRoleVariant(role) {
      if (role) {
        role = role.toLowerCase()
      }
      if (role === 'partner') return 'warning'
      if (role === 'admin') return 'danger'
      if (role === 'user') return 'success'
      return 'primary'
    },
    resolveUserRoleIcon(role) {
      if (role) {
        role = role.toLowerCase()
      }
      if (role === 'user') return 'UserIcon'
      if (role === 'partner') return 'BriefcaseIcon'
      if (role === 'admin') return 'ServerIcon'
      return 'SmileIcon'
    },
    resolveUserStatusVariant(status) {
      if (status === 'active') return 'success'
      if (status === 'inactive') return 'secondary'
      return 'primary'
    },
    resolveSourceVariant(source){
      if (source === 'own') return 'light-success'
      if (source === 'school') return 'light-warning'
      if (source === 'recommended') return 'light-danger'
      if (source === 'shared') return 'light-secondary'
      return 'light-info'
    },
    humanReadableTime(s){
      let t = ''

      if(s>60){
        let m = Math.trunc(s/60)
        let s_resto = s - (m*60)

        if(m>60){
          let h = Math.trunc(m/60)
          let m_resto = m - (h*60)

          t = h+'h '
          if (m_resto>0) t+=  m_resto+'m '
          if (s_resto>0) t+= s_resto+'s'
        } else {
          t = m+ 'm ' 
          if (s_resto>0) t+= s_resto+'s'
        }

      } else {
        t= s+'s'
      }
      return t
    },
    /* Check access token */
    checkAccessToken() {
      // Comprobar si la URL actual contiene '_sso/login', 'login?error', '/forgot-password' o '/reset-password'
      if (window.location.href.includes('_sso/login') || window.location.href.includes('login') || window.location.href.includes('/forgot-password') || window.location.href.includes('/reset-password')) {
        return;
      }
      const accessToken = localStorage.getItem('accessToken');

      if (!accessToken) {
        // No hay accessToken, ejecutar logout y redirigir a la página de inicio de sesión
        // console.log('No hay accessToken. Debes realizar login');

        // Redirigir a la página de inicio de sesión
        this.$router.push({ name: 'auth-login' });
      } else {
        // console.log('accessToken presente. Todo está bien.');
      }
    },

    /* AcceptPrivacy */
    async checkAcceptPrivacyAsync() {
      /* @REVISAR JENN --> cam biar $store por localStorage */
      const privacyAccepted = this.$store.getters['user/getUserData'].privacy_accepted;
  
      if (privacyAccepted === null) {
        // console.log('No se han aceptado los términos y condiciones. Ejecutando logout...');
        } else {
        const isLoggedIn = this.$store.getters['user/isLoggedIn'];
                if (isLoggedIn) {
          // console.log('Has aceptado los términos y condiciones. Todo está bien.');
        } else {
          // Si no se ha iniciado sesión, puedes realizar otras acciones necesarias
        }
      }
    },
    logout() {
      // Lógica de logout
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);
      localStorage.removeItem('userData');
      this.$ability.update(initialAbility);
      // this.$router.push({ name: 'auth-login' });
    },
  },
})

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

/**
 * Uncomment below when compiling to production
 
 Vue.config.devtools = false
 Vue.config.debug = false
 Vue.config.silent = true
 
 */

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  created() {
    // Llama a checkAccessToken después de la creación de la instancia Vue
    this.checkAccessToken();
  
    // Se verifica si hay accessToken cada 15 minutos
    setInterval(this.checkAccessToken, 900000);
  
    // Llama a checkAcceptPrivacy después de la creación de la instancia Vue
    this.checkAcceptPrivacyAsync();

    // En tu componente Vue o en tu archivo main.js
    window.addEventListener('userLoggedOut', () => {
      // Lógica de logout
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);
      localStorage.removeItem('userData');
      this.$ability.update(initialAbility);
    });
  },
}).$mount('#app')
